import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Fundraising is a marshmellow test`}</h2>
    <p>{`Perhaps the most surprising thing I learned during my time as a visiting partner at YC was that very few Fortune 100s raised significant capital prior to IPO. Times were different before Sarbanes-Oxley, with much of the public market and hedge fund activity now happening within the VC ecosystem. But that's not enough to explain the data.`}</p>
    <p>{`The typical path for a fortune 100 is the following:`}</p>
    <ol>
      <li parentName="ol">{`Founder has some small business experience or was a fast rising exec at a larger business`}</li>
      <li parentName="ol">{`Founder self funds, gets friends and family funding, or a small loan from a regional bank / high net worth individual in the community`}</li>
      <li parentName="ol">{`Business starts selling something small in short supply and high demand`}</li>
    </ol>
    <p>{`"But what about deep tech?"
-> The first airplanes were bicycles
-> The auto companies sold parts`}</p>
    <p>{`Building a plane on day 1 is usually not a great idea. You might have experts on the team, but greatness is achieved by teams growing together!`}</p>
    <h3>{`The VC / LP relationship`}</h3>
    <p>{`VC's are employees of their LPs. VC's are paid salaries and equity bonuses by their LPs. VCs do not control their own funds, their LPs do. No matter what a VC promises you, that promise is hamstrung by decisions their LPs can make for them - implicitly or explicitly.
What is true today `}</p>
    <p>{`Where VC's make returns - entirely in Seed / A stage`}</p>
    <p>{`The lies VC's tell you - narratives around businesses. What you "need" to do.
Why do they tell these lies? They are at mercy of their LPs
Many VCs I know are high school classmates of mine. Menlo -> Ivy League -> Big tech -> VC. Parallels the education pathway into NYC finance in the 90s/00s
They are as insecure in their jobs as you are, which means they are making decisions on their back foot
Many are 30, and were too young to meaningfully remember the dot com and financial crash
Just like hedge funds before them, they are in the business of selling capital, buying businesses, and repackaging them for profit`}</p>
    <p>{`VCs don't have the ability to keep promises to you if they leave their firm, which happens often!
Junior partners != senior partners
"partner" != partner`}</p>
    <p>{`Permanent damage to the organizational culture, permanent damage to the incentive structure
"I'm so focused on the next step, I can't think 5 steps ahead"
Optimizing from first sale to IPO`}</p>
    <p>{`The classic marshmellow test`}</p>
    <ul>
      <li parentName="ul">{`"we think you should be more aggressive with growth. Don't take $1m on $15m from us, take $2m on $20m from us"`}</li>
      <li parentName="ul">{`"you need to raise VC otherwise it's not a real business. customers and employees will be hard to recruit"`}</li>
      <li parentName="ul">{`"oh you're not raising VC? great to hear you're building a lifestyle business!"`}</li>
    </ul>
    <p>{`Waiting to raise is `}<em parentName="p">{`usually`}</em>{` a good idea`}</p>
    <ul>
      <li parentName="ul">{`It's exceptionally rare that a founder comes to me not wanting to raise when they should`}</li>
      <li parentName="ul">{`Very common a founder comes to me wanting to raise when they have mediocre metrics and plenty of tools under their belt to increase revenue / leverage`}</li>
    </ul>
    <p>{`You have ONE source of leverage against VCs: Revenue`}</p>
    <ul>
      <li parentName="ul">{`VCs are in the business of providing you capital. They only have a business if they sell you their capital`}</li>
      <li parentName="ul">{`VCs are not giving you something or supporting your business or "partnering" with you. They are SELLING you. The best VCs I know are the best salespeople. `}</li>
      <li parentName="ul">{`They'll make claims to "support" you, but VC doesn't operate in the same way it used to`}</li>
      <li parentName="ul">{`VCs are attempting to `}<strong parentName="li">{`monopolize`}</strong>{` the capital source for `}</li>
      <li parentName="ul">{`If you have no other options, and they convince you chasing revenue is a bad idea, you have no choice but to buy their product`}</li>
    </ul>
    <p><strong parentName="p">{`VC then vs VC now`}</strong>{`
Sequoia / Apple story
Seqouia today
6 boards vs 15+ boards`}</p>
    <p>{`What does end game look like?`}</p>
    <ul>
      <li parentName="ul">{`Private equity bloodbath`}</li>
      <li parentName="ul">{`Great products decay, employees don't share in profits`}</li>
      <li parentName="ul">{`We reinvent the same technology over and over again, at a huge cost to society (wasted time, lost knowledge / data)`}</li>
    </ul>
    <p>{`So what can you do?`}</p>
    <ol>
      <li parentName="ol">{`Chase revenue. Be default alive. `}</li>
      <li parentName="ol">{`Bootstrapping doesn't mean you should be less aggressive with growth...`}</li>
      <li parentName="ol"></li>
    </ol>
    <p>{`The best businesses`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      